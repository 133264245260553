import './shared';
import * as library from "./library";

// Images not in CSS
library.importAllImages(require.context('./imgs/', true, /\.(png|jpe?g|gif|webp|ico|svg)(\?.*)?$/));

// import 'bootstrap/dist/css/bootstrap.css';
import './theme/front.scss';

import './modules/front';
import naja from "naja";


// Webpack HMR
if (module.hot) {
	module.hot.accept();
}

