// Required dependencies
import 'jquery';
import * as bootstrap from 'bootstrap';

window.bootstrap = bootstrap;

import netteForms from 'nette-forms';

window.Nette = netteForms;

netteForms.initOnLoad();

console.log('Test');

$(document).ready(function () {
	console.log('jQuery ready');
});
